import React from 'react';
import {InstantSearch, SearchBox, Pagination, Configure, RefinementList} from 'react-instantsearch-dom';
import {instantMeiliSearch} from '@meilisearch/instant-meilisearch';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import CustomHits from './Hit';

const searchClient = instantMeiliSearch(
    process.env.REACT_APP_MEILISEARCH_HOST,
    process.env.REACT_APP_MEILISEARCH_KEY,
);

const App = () => (
    <div className="ais-InstantSearch">
        <InstantSearch indexName="application"
                       searchClient={searchClient}>
            <Configure
                hitsPerPage={20}
                // attributesToSnippet={["description:10"]}
                snippetEllipsisText={"..."}
            />
            <SearchBox
                translations={{
                    placeholder: 'Поиск проекта',
                }}
            />
            <Container>
                <Row>
                    <Col xs={{span: 6, offset: 3}}>
                        <h2 className="mt-4 mb-3">Проекты</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3} lg={3} xl={3}>
                        <h4>Индустрия</h4>
                        <RefinementList attribute="industry"
                        />

                        <h4 className="mt-4">Регион</h4>
                        <RefinementList
                            attribute="region"
                        />
                        <h4 className="mt-4">Статус</h4>
                        <RefinementList attribute="status"/>
                    </Col>
                    <Col xs={12} md={9} lg={9} xl={9}>
                        <Row>
                            <CustomHits/>
                            <Pagination showLast={true}/>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </InstantSearch>
    </div>
);


export default App