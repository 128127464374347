import React from 'react';

function About() {
    return (
        <div>
            <p> Спасибо, что используете наш сервис Buscar по поиску проектов, поданных на получение стартап визы Испания!</p>
            <p> Этот сервис создан для того, чтобы вы могли проанализировать ранее поданные проекты, чтобы скорректировать свою бизнес-идею для избежания дублирования тем или выбора направлений с большим количеством отказов.
                Наш проект некоммерческий и не ставит цель заработать, мы хотим помочь максимальному количеству людей получить стартап визу и переехать в Испанию. <br />
                Наш телеграм канал: <a href="https://t.me/startup_visa_spain">Startup visa Spain</a>. Связаться с нами: <a href="https://t.me/Startupvisaspain">@Startupvisaspain</a><br />
            </p>
            <p>Поддержать проект вы можете, отправив любую сумму через bizum на номер 623425464 (в евро) или на <a href="https://www.tinkoff.ru/cf/AUoWUYseMJt">https://www.tinkoff.ru/cf/AUoWUYseMJt</a>  (в рублях).</p>
        </div>
    );
}

export default About;
