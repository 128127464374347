import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Copyright() {
    return (
        <footer className="py-3 my-4 border-top footer bg-light">
            <Container>
                <Row className="justify-content-between align-items-center">
                    <Col md={6} className="d-flex align-items-center">
                        <a href="http://elixired.com">Веб-разработка, MVP для стартапов</a>
                        <span className="text-muted">&nbsp; © 2023</span>
                    </Col>
                    <Col md={6}>
                        Разработано для сообщества «Стартап виза Испания»<br />
                        Телеграм канал: <a href="https://t.me/startup_visa_spain">Startup visa spain</a>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Copyright;
