import React, { useState } from 'react';
import moment from 'moment';
import { connectHits, Highlight, Snippet } from 'react-instantsearch-dom';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const Hit = ({ hit }) => {
    const [profileData, setProfileData] = useState(null);



    const [isProfileDataVisible, setProfileDataVisible] = useState(false);

    const toggleProfileData = () => {
        setProfileDataVisible(!isProfileDataVisible);
        if (!isProfileDataVisible) {
            loadProfileData(hit.profile.id);
        }
    };

    const loadProfileData = (profileId) => {
        axios
            .get(`/api/profiles/${profileId}/full`)
            .then((response) => {
                if (response.data && response.data.data) {
                    setProfileData(response.data.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className="col-md-6" key={hit.id}>
            <div className="card mb-6">
                <div className="card-body">
                    <h2 className="card-title">
                        <Highlight attribute="name" hit={hit} />
                    </h2>
                    {hit.website &&
                        <p className="card-text">
                            <a href={`http://${hit.website}`}>{hit.website}</a>
                        </p>
                    }

                    {hit.theme &&
                    <p className="card-text">
                        <Snippet attribute="theme" hit={hit} />
                    </p>
                    }
                    <div className="project-info">
                        <p className="card-text">
                            <strong>Количество фаундеров:</strong> {hit.founders}
                        </p>
                        <p className="card-text">
                            <strong>Статус:</strong>{' '}
                            {hit.status ? <Highlight attribute="status" hit={hit} /> : ''}
                        </p>
                        <p className="card-text">
                            <strong>Дата подачи:</strong>{' '}
                            {hit.submission_date ? moment(hit.submission_date).format('DD.MM.YYYY') : ''}
                        </p>
                        <p className="card-text">
                            <strong>Дата решения:</strong>{' '}
                            {hit.decision_date ? moment(hit.decision_date).format('DD.MM.YYYY') : ''}
                        </p>
                        <p className="card-text">
                            <strong>Регион:</strong>{' '}
                            {hit.region ? <Highlight attribute="region" hit={hit} /> : '-'}
                        </p>
                        <p className="card-text">
                            <strong>Tags:</strong>{' '}
                            {hit.hashtags ? <Highlight attribute="hashtags" hit={hit} /> : '-'}
                        </p>
                    </div>

                    {hit.profile?.id && hit.profile.is_public && (
                        <p className="card-text">
                            <a onClick={toggleProfileData} className="see-contacts">
                                Посмотреть контакты
                            </a>
                        </p>
                    )}

                    {(isProfileDataVisible && profileData) && (
                        <div className="contacts">
                            {profileData.name && <p>Имя: {profileData.name}</p>}
                            {profileData.whatsapp && <p>Whatsapp: {profileData.whatsapp}</p>}
                            {profileData.email && <p>Email: {profileData.email}</p>}
                            {profileData.telegram && <p>Telegram: {profileData.telegram}</p>}
                            {profileData.description && <p>Description: {profileData.description}</p>}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const Hits = ({ hits }) => (
    <>
        {hits.map((hit) => (
            <Hit key={hit.id} hit={hit} />
        ))}
    </>
);

const CustomHits = connectHits(Hits);
export default CustomHits;
