import React, {useState} from 'react';
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

import Container from 'react-bootstrap/Container';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

function ApplicationForm({onClose}) {
    //сообщение об отправке формы
    const initialState = {
        application: {
            name: null,
            theme: null,
            website: null,
            publishedAt: null,
            founders: null,
            industry: null,
            region: null,
            submission_date: null,
            decision_date: null,
            status: null,
            hashtags: null
        },
        profile: {
            is_public: false,
            name: null,
            email: null,
            telegram: null,
            whatsapp: null,
            description: null
        }
    }
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false); // Состояние для отображения сообщения об успехе
    const [formData, setFormData] = useState(initialState);

    const regionOptions = [
        {value: 'Avila', label: 'Avila'},
        {value: 'Alava', label: 'Alava'},
        {value: 'Alicante', label: 'Alicante'},
        {value: 'Albacete', label: 'Albacete'},
        {value: 'Almeria', label: 'Almeria'},
        {value: 'Asturias', label: 'Asturias'},
        {value: 'Badajoz', label: 'Badajoz'},
        {value: 'Baleares', label: 'Baleares'},
        {value: 'Barcelona', label: 'Barcelona'},
        {value: 'Vizcaya', label: 'Vizcaya'},
        {value: 'Burgos', label: 'Burgos'},
        {value: 'Valencia', label: 'Valencia'},
        {value: 'Valladolid', label: 'Valladolid'},
        {value: 'Guadalajara', label: 'Guadalajara'},
        {value: 'Guipuzcoa', label: 'Guipuzcoa'},
        {value: 'Granada', label: 'Granada'},
        {value: 'Gerona', label: 'Gerona'},
        {value: 'Cadiz', label: 'Cadiz'},
        {value: 'Cantabria', label: 'Cantabria'},
        {value: 'Caceres', label: 'Caceres'},
        {value: 'Castellon', label: 'Castellon'},
        {value: 'Cordoba', label: 'Cordoba'},
        {value: 'Cuenca', label: 'Cuenca'},
        {value: 'La Coruna', label: 'La Coruna'},
        {value: 'La Rioja', label: 'La Rioja'},
        {value: 'Las Palmas', label: 'Las Palmas'},
        {value: 'Leon', label: 'Leon'},
        {value: 'Lerida', label: 'Lerida'},
        {value: 'Lugo', label: 'Lugo'},
        {value: 'Madrid', label: 'Madrid'},
        {value: 'Malaga', label: 'Malaga'},
        {value: 'Murcia', label: 'Murcia'},
        {value: 'Navarra', label: 'Navarra'},
        {value: 'Orense', label: 'Orense'},
        {value: 'Palencia', label: 'Palencia'},
        {value: 'Pontevedra', label: 'Pontevedra'},
        {value: 'Salamanca', label: 'Salamanca'},
        {value: 'Zamora', label: 'Zamora'},
        {value: 'Santa Cruz de Tenerife', label: 'Santa Cruz de Tenerife'},
        {value: 'Zaragoza', label: 'Zaragoza'},
        {value: 'Sevilla', label: 'Sevilla'},
        {value: 'Segovia', label: 'Segovia'},
        {value: 'Soria', label: 'Soria'},
        {value: 'Ciudad Real', label: 'Ciudad Real'},
        {value: 'Tarragona', label: 'Tarragona'},
        {value: 'Teruel', label: 'Teruel'},
        {value: 'Toledo', label: 'Toledo'},
        {value: 'Jaen', label: 'Jaen'},
        {value: 'Huelva', label: 'Huelva'},
        {value: 'Huesca', label: 'Huesca'},
    ];


    const extractDomain = (url) => {
        // Удаляем лишние знаки и www
        let domain = url.replace(/(https?:\/\/)?(www\.)?/i, '');

        // Удаляем все, что находится после первого слэша (/)
        const index = domain.indexOf('/');
        if (index !== -1) {
            domain = domain.substring(0, index);
        }

        return domain;
    };


    const handleChange = (event) => {
        const {id, value, type, checked} = event.target;
        const [section, field] = id.split('.');

        let updatedValue = value;
        if (type === 'checkbox') {
            updatedValue = checked; // Преобразование в логический тип
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [section]: {
                ...prevFormData[section],
                [field]: updatedValue,
            },
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
//        Валидация данных формы
        if (formData.application.name === null || formData.application.name.trim() === '') {
            alert('Введите название проекта');
            return;
        }

        // Обработка значения для application.website
        const domain = formData.application.website ? extractDomain(formData.application.website) : '';
        formData.application.website = domain;

        // Очистка полей в formData.application и formData.profile
        for (let obj of [formData.application, formData.profile]) {
            for (let field in obj) {
                if (typeof obj[field] === 'string') {
                    obj[field] = obj[field].trim();
                }
            }
        }

        //проверка profile на пустоту
        const isProfileFormFilled = () => {
            const {is_public, ...profileValues} = formData.profile;
            const values = Object.values(profileValues);
            return values.some(value => value !== null && value.trim() !== '');
        };

        //console.log(formData);

        if (isProfileFormFilled) {
            try {
                const profileResponse = await axios.post('/api/profiles', { data: formData.profile });
                if (profileResponse.data.data && profileResponse.data.data.id) {
                    formData.application.profile = profileResponse.data.data.id;
                }
                const applicationResponse = await axios.post('/api/applications', { data: formData.application });
                setShowSuccessMessage(true);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleSuccess = () => {
        // Скрыть сообщение об успешном добавлении формы
        setShowSuccessMessage(false);
    };

    return (
        <Container className="application-form">
            <h2>Добавьте свой проект</h2>
            <Form onSubmit={handleSubmit} className="gy-2 gx-3 align-items-center">
                <Row>
                    <Col xs={12} md={12} lg={8} xl={6}>
                        <Form.Group controlId="application.name">
                            <Form.Label>Название *</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.application.name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={8} xl={6}>
                        <Form.Group controlId="application.industry">
                            <Form.Label>Индустрия/сфера</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.application.industry}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={8} xl={6}>
                        <Form.Group controlId="application.hashtags">
                            <Form.Label>Хештеги по категории</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.application.hashtags}
                                placeholder="Введите хештеги, через запятую"
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={8} xl={6}>
                        <Form.Group controlId="application.theme">
                            <Form.Label>Описание проекта</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={formData.application.theme}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={8} lg={5} xl={4}>
                        <Form.Group controlId="application.website">
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                                type="text"
                                value={formData.application.website}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={8} lg={5} xl={4}>
                        <Form.Group controlId="application.region">
                            <Form.Label>Регион</Form.Label>
                            <Form.Select
                                value={formData.application.region}
                                onChange={handleChange}
                            >
                                <option value="">Выберите регион</option>
                                {regionOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col xs={6} md={4} lg={3} xl={2}>
                        <Form.Group controlId="application.founders">
                            <Form.Label>Количество фаундеров</Form.Label>
                            <Form.Control
                                type="number"
                                value={formData.application.founders}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4} lg={3} xl={2}>
                        <Form.Group controlId="application.status">
                            <Form.Label>Статус</Form.Label>
                            <Form.Control
                                as="select"
                                value={formData.application.status}
                                onChange={handleChange}
                            >
                                <option value="На рассмотрении">На рассмотрении</option>
                                <option value="Успешно">Успешно</option>
                                <option value="Отклонено">Отклонено</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>

                    <Col xs={12} md={3} lg={2} xl={2}>
                        <Form.Group controlId="application.submission_date">
                            <Form.Label>Дата подачи</Form.Label>
                            <Form.Control
                                type="date"
                                value={formData.application.submission_date}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>

                    <Col xs={6} md={3} lg={2} xl={2}>
                        <Form.Group controlId="application.decision_date">
                            <Form.Label>Дата решения</Form.Label>
                            <Form.Control
                                type="date"
                                value={formData.application.decision_date}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <h4 className="pt-4 mt-5 mb-3"> Контакты </h4>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Form.Group controlId="profile.is_public">
                            <Form.Check
                                type="checkbox"
                                name="is_public"
                                label="Согласны опубликовать ваши контакты?"
                                checked={formData.profile.is_public}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} lg={4} xl={3}>
                        <Form.Group controlId="profile.name">
                            <Form.Label>Имя</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.profile.name}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={3}>
                        <Form.Group controlId="profile.whatsapp">
                            <Form.Label>Whatsapp</Form.Label>
                            <Form.Control
                                type="text"
                                name="whatsapp"
                                value={formData.profile.whatsapp}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} lg={4} xl={3}>
                        <Form.Group controlId="profile.email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.profile.email}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={3}>
                        <Form.Group controlId="profile.telegram">
                            <Form.Label>Telegram</Form.Label>
                            <Form.Control
                                type="text"
                                name="telegram"
                                value={formData.profile.telegram}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={8} xl={6}>
                        <Form.Group controlId="profile.description">
                            <Form.Label>Комментарий</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={formData.profile.description}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className="m-3 mb-5">
                    <Col xs={12} md={12} lg={8} xl={6} className="text-center">
                        <Button disabled={showSuccessMessage} type="submit">
                            Сохранить проект
                        </Button>
                    </Col>
                </Row>
            </Form>

            {showSuccessMessage && (
                <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
                    Проект добавлен в базу.
                </Alert>
            )}

        </Container>);
}

export default ApplicationForm;



