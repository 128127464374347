import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import "instantsearch.css/themes/algolia-min.css";
import DataSearch from './components/Data/DataSearch';
import ApplicationForm from './components/Forms/ApplicationForm';
import Copyright from './components/Copyright';
import About from './components/About';

import './App.css';


function App() {
    const [isFormOpen, setIsFormOpen] = useState(false);

    const handleFormOpen = () => {
        setIsFormOpen(true);
    };

    const handleFormClose = () => {
        setIsFormOpen(false);
    };

    const handleButtonClick = () => {
        handleFormOpen();
    };

    return (
        <div className="App">
            <Container>
                <nav className="navbar bg-body-tertiary navbar-light bg-light border-bottom navbar-expand-lg bg-body-tertiary">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">Стартап <span>&#x1F1EA;&#x1F1F8;</span></a>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <span className="nav-link active" role="button" aria-current="page" onClick={handleFormClose}>Проекты</span>
                            </li>
                            <li>
                                <Button onClick={handleButtonClick} className="btn-small">
                                    Добавить проект
                                </Button>
                            </li>
                        </ul>
                    </div>
                </nav>
                {isFormOpen ? (
                    <ApplicationForm onClose={handleFormClose}/>
                ) : (
                    <>
                        <About/>
                        <DataSearch onOpenForm={handleFormOpen}/>
                    </>
                )}

                <Copyright/>
            </Container>
        </div>
    );
}

export default App;





